import type { CoBranding } from "@lib/strapi/types";
import { getLimitedCardInfo, type LimitedCardInfo } from "@lib/tangem.ts";

export type CounterParams = Pick<
  CoBranding,
  | "sku"
  | "isDirectCounter"
  | "numberOfWallets"
  | "isSaleAvailable"
  | "additionalValue"
  | "locale"
> & { buyButtonClassName: string };

export type CounterElements = {
  id: string;
  value: number;
};

const ID_PREFIX = "limit_";

export class Counter {
  constructor(counterParams: CounterParams) {
    this._params = counterParams;
    this._size = counterParams.numberOfWallets.toString().length;
    this._displayValue = counterParams.isDirectCounter
      ? 0
      : counterParams.numberOfWallets;
  }

  private readonly _params: CounterParams;
  private _wallet: LimitedCardInfo | undefined;
  private readonly _size: number;
  private _displayValue: number;

  get canBuy() {
    return this._wallet
      ? this._wallet.sold < this._params.numberOfWallets &&
          this._params.isSaleAvailable
      : false;
  }

  get wallet(): LimitedCardInfo {
    return <LimitedCardInfo>this._wallet;
  }

  get params(): CounterParams {
    return this._params;
  }

  get isDirect() {
    return this._params.isDirectCounter;
  }

  get counterElements(): CounterElements[] {
    const valueString = (
      Array(this._size).fill("0").join("") + this._displayValue
    ).slice(-this._size);
    return [...Array(this._size)].map((_, i) => ({
      id: this.getId(i),
      value: parseInt(valueString[i]) || 0,
    }));
  }

  async getData() {
    try {
      const data = await getLimitedCardInfo(this._params.sku, {
        locale: this._params.locale,
      });
      if (data) {
        this._wallet = data;
        this.setDisplayData(this._wallet.sold);
      }
    } catch (e) {
      //
    }
  }

  private setDisplayData(sold: number) {
    this._displayValue = this._params.isDirectCounter
      ? Math.min(
          sold + this._params.additionalValue,
          this._params.numberOfWallets,
        )
      : Math.max(
          0,
          this._params.numberOfWallets - sold + this._params.additionalValue,
        );
  }

  private getId(index: number) {
    return ID_PREFIX + (this._size - index);
  }

  async refreshData() {
    await this.getData();
    const buttons = document.querySelectorAll<HTMLButtonElement>(
      `.${this._params.buyButtonClassName}`,
    );
    if (this.canBuy) {
      this.counterElements.map(({ id, value }) => {
        const element = document.getElementById(id);
        if (element) {
          element.innerText = value.toString();
        }
      });
      buttons.forEach((button) => button.removeAttribute("disabled"));
    } else {
      buttons.forEach((button) => (button.style.visibility = "hidden"));
      const root = document.querySelector(".limit");
      if (root) {
        root.classList.add("limit_sold");
      }
    }
  }
}
